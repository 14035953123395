import { render, staticRenderFns } from "./proceed_approve_pc.vue?vue&type=template&id=2db41426&scoped=true"
import script from "./proceed_approve_pc.vue?vue&type=script&lang=js"
export * from "./proceed_approve_pc.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2db41426",
  null
  
)

export default component.exports