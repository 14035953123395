<template>
	<proceed-list scene="approve"></proceed-list>
</template>

<script>
	import proceedList from './proceed_list_pc.vue'
	
	export default {
		name: 'proceed_approve',
		
		components: {
			proceedList
		}
	};
</script>

<style lang="stylus" scoped>
</style>